<template>
  <div class="vehicle-main">
    <v-data-table
      :headers="headers"
      :items="vechicles"
      sort-by="vehicle_no"
      class="elevation-1"
    >
      <template v-slot:item.current_driver="{ item }">
        <div>{{ getUserName(item.current_driver) }}</div>
      </template>
      <!-- <template v-slot:item.previous_driver="{ item }">
        <div>{{ getUserName(item.previous_driver) }}</div>
      </template> -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="primary--text"
            ><h3>Vehicles</h3></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon> New
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h6 primary--text">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text class="mt-5 pb-0">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6" class="py-0 my-0">
                      <v-text-field
                        v-model="editedItem.vehicle_model"
                        label="Vehicle Model"
                        filled
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-0">
                      <v-text-field
                        v-model="editedItem.vehicle_no"
                        label="Vehicle Number"
                        filled
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-0">
                      <v-text-field
                        v-model="editedItem.type"
                        label="Type"
                        filled
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-0">
                      <v-dialog
                        ref="date_dialog_i"
                        v-model="date_model_i"
                        :return-value.sync="editedItem.insurance"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.insurance"
                            label="Insurance"
                            filled
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.insurance"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="date_model_i = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.date_dialog_i.save(editedItem.insurance)
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-0">
                      <v-dialog
                        ref="date_dialog"
                        v-model="date_model"
                        :return-value.sync="editedItem.revenue_license"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.revenue_license"
                            label="Revenue License"
                            filled
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.revenue_license"
                          scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="date_model = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.date_dialog.save(editedItem.revenue_license)
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-0">
                      <v-text-field
                        v-model="editedItem.oil_to_be_changed"
                        label="Last oil changed"
                        filled
                        clearable
                        @input="changeNextOil"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-0">
                      <v-text-field
                        v-model="editedItem.oil_change_km"
                        label="Oil change km"
                        filled
                        clearable
                        @input="changeNextOil"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-0">
                      <v-text-field
                        v-model="editedItem.next_oil_to_be_changed"
                        label="Next oil to be changed"
                        filled
                        readonly
                        dark
                        background-color="secondary"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-0">
                      <v-text-field
                        v-model="editedItem.oil_change_notification_km"
                        label="Oil change Notification km"
                        filled
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-0">
                      <v-select
                        v-model="editedItem.current_driver"
                        label="Current Driver"
                        :items="driverItems"
                        :item-text="
                          (item) => `${item.first_name} ${item.last_name}`
                        "
                        item-value="user_id"
                        filled
                      ></v-select>
                    </v-col>
                    <!-- <v-col cols="12" md="6" class="py-0 my-0">
                      <v-select
                        v-model="editedItem.previous_driver"
                        label="Previous Driver"
                        :items="pDriverItems"
                        :item-text="
                          (item) => `${item.first_name} ${item.last_name}`
                        "
                        item-value="user_id"
                        filled
                      ></v-select>
                    </v-col> -->
                    <v-col cols="12" class="py-0 my-0">
                      <v-text-field
                        v-model="editedItem.remarks"
                        label="Remarks"
                        filled
                        clearable
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close"> Cancel </v-btn>
                <v-btn
                  color="primary"
                  @click="
                    save();
                    snackbar = true;
                  "
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
    <v-btn class="mb-3 ml-3 mt-3">
      <export-excel
        :data="vechicles"
        :name="excel_name"
        :fields="excel_headders"
      >
        Download Excel
      </export-excel>
    </v-btn>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
const axios = require("axios").default;

export default {
  data: () => ({
    excel_name:
      "Vehicles" +
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10) +
      ".xls",
    dialog: false,
    date_model: false,
    date_model_i: false,
    headers: [
      {
        text: "Vehicle Model",
        align: "start",
        sortable: false,
        value: "vehicle_model",
      },
      { text: "Vehicle Number", value: "vehicle_no" },
      { text: "Type", value: "type" },
      { text: "Insurance", value: "insurance" },
      { text: "Revenue License", value: "revenue_license" },
      { text: "Last oil changed", value: "oil_to_be_changed" },
      { text: "Oil change km", value: "oil_change_km" },
      {
        text: "Oil change notification km",
        value: "oil_change_notification_km",
      },
      { text: "Next oil to be changed", value: "next_oil_to_be_changed" },
      { text: "Current Driver", value: "current_driver" },
      // { text: "Previous Driver", value: "previous_driver" },
      { text: "Remarks", value: "remarks" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    excel_headders: {
      "Vehicle Model": "vehicle_model",
      "Vehicle Number": "vehicle_no",
      "Type": "type",
      "Insurance": "insurance",
      "Revenue License": "revenue_license",
      "Last oil changed": "oil_to_be_changed",
      "Oil change km": "oil_change_km",
      "Oil change notification km": "oil_change_notification_km",

      "Next oil to be changed": "next_oil_to_be_changed",
      "Current Driver": "current_driver",
      "Remarks": "remarks",
    },
    vechicles: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      vehicle_model: "",
      vehicle_no: "",
      type: "",
      insurance: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      revenue_license: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      oil_to_be_changed: 0,
      oil_change_km: 2500,
      oil_change_notification_km: 50,
      next_oil_to_be_changed: 0,
      current_driver: "",
      previous_driver: "",
      remarks: "",
    },
    defaultItem: {
      vehicle_model: "",
      vehicle_no: "",
      type: "",
      insurance: "",
      revenue_license: "",
      oil_to_be_changed: 0,
      oil_change_km: 2500,
      oil_change_notification_km: 50,
      next_oil_to_be_changed: 0,
      current_driver: "",
      previous_driver: "",
      remarks: "",
    },
    driverItems: [],
    pDriverItems: "",
    snackbar: false,
    text: ``,
    timeout: 4000,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    changeNextOil() {
      this.editedItem.next_oil_to_be_changed = parseFloat(
        parseFloat(this.editedItem.oil_to_be_changed) +
          parseFloat(this.editedItem.oil_change_km)
      );
    },
    getUserName(user_id) {
      let driver =
        this.driverItems[
          this.driverItems.findIndex((element) => element.user_id == user_id)
        ];
      if (driver) {
        return driver.first_name + " " + driver.last_name;
      } else {
        return "-";
      }
    },
    editItem(item) {
      this.editedIndex = this.vechicles.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    readDataFromAPI() {
      axios.get(this.$backend_url + "vehicles").then((response) => {
        this.vechicles = response.data.vehicles;
      });
    },

    readUsersDataFromAPI() {
      axios
        .get(this.$backend_url + "drivers")
        .then((response) => {
          if (response.data) {
            this.driverItems = response.data.drivers;
            this.pDriverItems = response.data.drivers;
          }
        })
        .catch((res) => {
          console.log("Errors:", res);
        });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.vechicles[this.editedIndex], this.editedItem);
        axios
          .put(this.$backend_url + "vehicles", {
            id: this.editedItem.id,
            vehicle_no: this.editedItem.vehicle_no,
            vehicle_model: this.editedItem.vehicle_model,
            type: this.editedItem.type,
            insurance: this.editedItem.insurance,
            revenue_license: this.editedItem.revenue_license,
            oil_to_be_changed: this.editedItem.oil_to_be_changed,
            oil_change_km: this.editedItem.oil_change_km,
            oil_change_notification_km:
              this.editedItem.oil_change_notification_km,
            next_oil_to_be_changed: this.editedItem.next_oil_to_be_changed,
            current_driver: this.editedItem.current_driver,
            previous_driver: this.editedItem.previous_driver,
            remarks: this.editedItem.remarks,
          })
          .then((res) => {
            if (res.data.success === true) {
              this.text = `Vehicle has been successfully modified.`;
            }
            if (res.data.success === false) {
              this.text = `Vehicle already exists with same number!!!`;
            }
          })
          .catch((res) => {
            console.log("Errors:", res);
            this.text = `Error occured while sending data!`;
            window.location.reload();
          });
      } else {
        axios
          .post(this.$backend_url + "vehicles", {
            vehicle_no: this.editedItem.vehicle_no,
            vehicle_model: this.editedItem.vehicle_model,
            type: this.editedItem.type,
            insurance: this.editedItem.insurance,
            revenue_license: this.editedItem.revenue_license,
            oil_to_be_changed: this.editedItem.oil_to_be_changed,
            oil_change_km: this.editedItem.oil_change_km,
            oil_change_notification_km:
              this.editedItem.oil_change_notification_km,
            next_oil_to_be_changed: this.editedItem.next_oil_to_be_changed,
            current_driver: this.editedItem.current_driver,
            previous_driver: this.editedItem.previous_driver,
            remarks: this.editedItem.remarks,
          })
          .then((res) => {
            if (res.data.success === true) {
              this.text = `Vehicle has been successfully added.`;
              window.location.reload();
              this.vechicles.push(this.editedItem);
            }
          })
          .catch((res) => {
            if (res.response.status == 422) {
              this.text = `Vehicle already exists!`;
              console.log("Errors:", res);
            }
            if (res.response.status == 500) {
              this.text = `Error occured while sending data.`;
              console.log("Errors:", res);
            }
            if (res.response.status == 400) {
              this.text = `Bad HTTP Request`;
              console.log("Errors:", res);
            }
          });
      }
      this.close();
    },
  },

  mounted() {
    if (
      !(
        JSON.parse(localStorage.imso_user).role == 1 ||
        JSON.parse(localStorage.imso_user).role == 4
      )
    ) {
      this.$router.push("/");
    }
    this.readDataFromAPI();
    this.readUsersDataFromAPI();
  },
};
</script>

<style>
.vehicle-main {
  padding: 20px;
  margin-left: 50px;
  width: calc(100vw - 60px);
  min-height: calc(100vh - 200px);
}
@media only screen and (max-width: 960px) {
  .vehicle-main {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .vehicle-main {
    padding: 20px;
    margin-left: 0px;
    width: 100vw;
  }
}
</style>